<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.orgs")
        }}</v-toolbar-title>
        <v-spacer />
        <!--         <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn text x-small :disabled="!selected" @click="clearSelectedOrganization()">
          <v-icon>mdi-circle-off-outline</v-icon>
          <span class="text--secondary">{{ $t("general.deselect") }}</span>
        </v-btn> -->
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          :disabled="isReadOnly"
          text
          x-small
          color="green"
          @click="$router.push({ name: 'org', params: { id: 'new' } })"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="primary"
          :disabled="!selected"
          @click="editOrg()"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t("general.edit") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="red"
          v-if="$hasRole('Administrator')"
          :disabled="!selected"
          @click="deleteSelected()"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t("general.delete") }}</span>
        </v-btn>
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
        <v-data-table
          dense
          :headers="headers"
          :items="getOrganizations"
          item-key="id"
          :search="search"
          show-select
          single-select
          @item-selected="selectionChanged"
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="blue"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Orgs",
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 50 },
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("org.acronym"), value: "acronym" },
        { text: this.$t("general.type"), value: "type" },
        // { text: "Actions", value: "actions", align: "right", width: 250 }
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.loadOrganizations()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },
  computed: {
    ...mapGetters("organizations", ["getOrganizations"]),
    ...mapState({
      selected(state) {
        return state.organizations.selectedOrg;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),
  },
  methods: {
    ...mapActions("organizations", [
      "loadOrganizations",
      "deleteOrganization",
      "setSelectedOrganization",
      "clearSelectedOrganization",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    selectionChanged(event) {
      if (event.value) {
        this.setSelectedOrganization(event.item);
      } else {
        this.clearSelectedOrganization();
      }
    },
    editOrg() {
      if (this.selected) {
        this.$router.push({
          name: "org",
          params: { id: this.selected.id },
        });
      }
    },
    deleteSelected() {
      if (this.selected) {
        const dialog = {
          title: "Delete",
          text: `Are you sure you want to delete ${this.selected.name} organization?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.deleteOrganization(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
  },
};
</script>
